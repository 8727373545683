














import Widget from "@/components/common/Widget.vue";
import SmallPie from "@/components/charts/SmallPie.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    SmallPie,
  },
})
export default class Top5BereicheGesamt extends Vue {
  public static id = "Top5BereicheGesamt";
  public static dprops = { width: 4, height: 4, minWidth: 2, minHeight: 2 };
  get _id() {
    return Top5BereicheGesamt.id;
  }
  get _dprops() {
    return Top5BereicheGesamt.dprops;
  }
}
