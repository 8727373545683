




























































import { Watch, Component, Vue } from "vue-property-decorator";
import DataTable from "@/components/table/DataTable.vue";
import SelectFilter from "@/components/common/SelectFilter.vue";
import api from "../../api";
import store from "../../store";
import { germanZahl } from "@/helper/utils";


const TABLE_COLUMNS = [
  {
    label: "Konto",
    prop: "o.sollhaben",
    sortable: true,
  },
  {
    label: "Name",
    prop: "o.ktonr",
    sortable: true,
    bodyCellClass: 'text-left'
  },
  {
    label: "Re.-Nr",
    prop: "k.name",
    sortable: true,
  },
  {
    label: "Re.-Datum",
    prop: "o.rechnnr",
    sortable: true,
  },
  {
    label: "Fälligkeit",
    prop: "o.rechndatum",
    sortable: true,
  },
  {
    label: "Fällig seit",
    prop: "o.faelligkeit",
    sortable: true,
  },
  {
    label: "Betrag",
    prop: "faellength",
    sortable: true,
  },
  {
    label: "S/H",
    prop: "o.umsatzbasisw",
    sortable: true,
  }
];


@Component({
  components: { DataTable, SelectFilter }
})
export default class OposTable extends Vue {
    public tableColums = TABLE_COLUMNS;
    public tableRows: Array<any> = [];

    // Sort
    public totalItems = 0;
    public perPage = 25;
    public perPageList = [10, 25, 50, 100 , 250 , 500];
    public offsetPage = 0;
    public orderby = "o.sollhaben";
    public orderDirection = "asc";

    // Filter
    public filterKtonr: Array<any> | null = null;
    public filterRechnnr: Array<any> | null = null;
    public filterKtonrList: Array<any> = [];
    public filterRechnnrList: Array<any> = [];

    public saldo = 0;

    get selectedYear() {
        return store.getters.selectedYear;
    }

    get selectedClient() {
        return store.getters.selectedClient;
    }

    get requestData() {
        return {
            selectedClient: this.selectedClient,
            selectedYear: this.selectedYear
        };
    }

    @Watch("requestData")
    updateRequestData(val: any, oldVal: any) {
        if (val.selectedClient && val.selectedYear) {
            let updateClient = !oldVal.selectedClient || val.selectedClient[0] !== oldVal.selectedClient[0] || val.selectedClient[1] !== oldVal.selectedClient[1];
            let updateYear = !oldVal.selectedYear || val.selectedYear[0] !== oldVal.selectedYear[0];
            if (updateClient || updateYear) {
                this.perPage = 25;
                this.offsetPage = 0;
                this.orderby = "o.sollhaben";
                this.orderDirection = "asc";
                this.filterKtonr = null;
                this.filterRechnnr = null;
                this.filterKtonrList = [];
                this.filterRechnnrList = [];
                this.fetchData();
            }
        }
    }

    changeSortDirection(val: any) {
        this.orderDirection = val;
        this.getOposTableData();
    }

    changeSortProperty(val: any) {
        this.orderby = val;
        this.orderDirection = "asc";
        this.getOposTableData();
    }

    changePerPage(val: any): void {
        if (val) {
            let offsetPage = val - 1;
            if (offsetPage !== this.offsetPage) {
                this.offsetPage = offsetPage;
                this.getOposTableData();
            }
        }
    }

    changefilter(val: any, key: string) {
        this.offsetPage = 0;
        this.getOposTableData();
    }

    getFilters() {
        let filter: any = {};
        if (this.filterKtonr && this.filterKtonr.length) {
            filter["o.ktonr"] = this.filterKtonr;
        }
        if (this.filterRechnnr && this.filterRechnnr.length) {
            filter["o.rechnnr"] = this.filterRechnnr;
        }
        return filter;
    }

    getOposTableData() {
        let parms: any = {};
        parms["limit"] = this.perPage;
        parms["offset"] = this.offsetPage * this.perPage;
        parms["orderby"] = this.orderby;
        parms["orderdir"] = this.orderDirection;
        parms["debkred"] = 'BOTH';

        let filter: any = this.getFilters();

        // Apply filter only if anything is set
        if (Object.keys(filter).length) {
            parms["filter"] = filter;
        }

        return api.getOposTableData(this.selectedClient, this.selectedYear, parms).then((resp: any) => {
            let d = resp.data.opos_data;
            this.saldo = resp.data.saldo_total;

             // Detect number of records. This comes in last column of dataset
            if (d && d.length > 0) {
                this.totalItems = d[0][8];
            }

            // Dataset postprocessing
            for (let idx in d) {
            
                // Append "Tag" or "Tage"
                if (d[idx][6]) {
                    if (parseInt(d[idx][6]) == 1) {
                        d[idx][6] = d[idx][6] + " Tag";
                    }
                    else {
                        d[idx][6] = d[idx][6] + " Tagen";
                    }
                }

                // Proper formatting of €
                d[idx][7] = germanZahl(d[idx][7]);
            }

            for (let i = 0 ; i < d.length ; i ++) {
                var sh = d[i][0];
                d[i] = d[i].slice(1);
                d[i].splice(7, 0, sh);
            }

            let tableRows: Array<any> = [];
            d.forEach((z: any) => {
                let item: any = {};
                z.forEach((x: any, i: any) => {
                    let colItem = this.tableColums[i];
                    if (colItem) {
                        item[colItem.prop] = x;
                    }
                });
                tableRows.push(item);
            });

            this.tableRows = [...tableRows];
        }).catch(err => {
            this.tableRows = []
        });
    }

    getOposTableFilter() {
        return api.getOposTableFilter(this.selectedClient, this.selectedYear) .then((resp: any) => {
            let d = resp.data;
            this.filterKtonrList = d['ktonr'];
            this.filterRechnnrList = d['rechnnr'];
        }).catch(err => {
            this.filterKtonrList = []
            this.filterRechnnrList = []
        });
    }

    fetchData() {
        Promise.all([
            this.getOposTableData(), 
            this.getOposTableFilter()
        ]);
    }

    mounted() {
        if (this.requestData.selectedClient && this.requestData.selectedYear) {
            this.fetchData();
        }
    }
}
