

































import Widget from "@/components/common/Widget.vue";

import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";
import store from "@/store";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
  },
})
export default class Liquiditaetsstatus extends Vue {
  public static id = "Liquiditaetsstatus";
  public static dprops = { width: 8, height: 6, minWidth: 8, minHeight: 6 };
  @Prop() setRange!: boolean;
  get _id() {
    return Liquiditaetsstatus.id;
  }
  get _dprops() {
    return Liquiditaetsstatus.dprops;
  }
  get planSelected() {
    return store.getters.planSelected;
  }
}
