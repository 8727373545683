import BetriebsergebnisGesamt from "../components/DashboardItems/BetriebsergebnisGesamt.vue";
import BetriebsergebnisGesamtJahre from "../components/DashboardItems/BetriebsergebnisGesamtJahre.vue";
import ErfolgsrechnungTable from "../components/DashboardItems/ErfolgsrechnungTable.vue";
import KostenGesamtVergleich from "../components/DashboardItems/KostenGesamtVergleich.vue";
import KostenUndUmsaetze from "../components/DashboardItems/KostenUndUmsaetze.vue";
import Kostenbereiche from "../components/DashboardItems/Kostenbereiche.vue";
import Top10KundenBereichDrittUmsatz from "../components/DashboardItems/Top10KundenBereichDrittUmsatz.vue";
import Top10KundenBereichFuenftUmsatz from "../components/DashboardItems/Top10KundenBereichFuenftUmsatz.vue";
import Top10KundenBereichUmsatz from "../components/DashboardItems/Top10KundenBereichUmsatz.vue";
import Top10KundenBereichViertUmsatz from "../components/DashboardItems/Top10KundenBereichViertUmsatz.vue";
import Top10KundenBereichZweitUmsatz from "../components/DashboardItems/Top10KundenBereichZweitUmsatz.vue";
import BetriebsergebnisVergleich from "../components/DashboardItems/BetriebsergebnisVergleich.vue";
import Top10KundenUmsatz from "../components/DashboardItems/Top10KundenUmsatz.vue";
import Top10Umsatzbereiche from "../components/DashboardItems/Top10Umsatzbereiche.vue";
import Top10UmsatzerloeseBereiche from "../components/DashboardItems/Top10UmsatzerloeseBereiche.vue";
import Top10UmsatzerloeseKunden from "../components/DashboardItems/Top10UmsatzerloeseKunden.vue";
import Top5BereicheGesamt from "../components/DashboardItems/Top5BereicheGesamt.vue";
import Top5Kostenbereiche from "../components/DashboardItems/Top5Kostenbereiche.vue";
import Top5KostenbereichePieChart from "../components/DashboardItems/Top5KostenbereichePieChart.vue";
import Top5UmsatzerloeseBereiche from "../components/DashboardItems/Top5UmsatzerloeseBereiche.vue";
import Top5WareneinkaufGesamt from "../components/DashboardItems/Top5WareneinkaufGesamt.vue";
import Umsatzerloese from "../components/DashboardItems/Umsatzerloese.vue";
import BetriebswirtschaftlicheAuswertung from "../components/DashboardItems/BetriebswirtschaftlicheAuswertung.vue";
import UmsatzerloeseVergleich from "../components/DashboardItems/UmsatzerloeseVergleich.vue";
import Umsatzrentabilitaet from "../components/DashboardItems/Umsatzrentabilitaet.vue";
import Umsatzwachstum from "../components/DashboardItems/Umsatzwachstum.vue";
import WareneinkaufEtw from "../components/DashboardItems/WareneinkaufEtw.vue";
import WareneinkaufGesamtPieChart from "../components/DashboardItems/WareneinkaufGesamtPieChart.vue";
import EBITDAGesamt from "../components/DashboardItems/EBITDAGesamt.vue";
import EBITDAGesamtJahre from "../components/DashboardItems/EBITDAGesamtJahre.vue";
import EBITDAVergleich from "../components/DashboardItems/EBITDAVergleich.vue";
import EBITGesamt from "../components/DashboardItems/EBITGesamt.vue";
import EBITGesamtJahre from "../components/DashboardItems/EBITGesamtJahre.vue";
import EBITVorlaeufigesErgebnis from "../components/DashboardItems/EBITVorlaeufigesErgebnis.vue";
import BuchungssaetzeTable from "../components/DashboardItems/BuchungssaetzeTable.vue";
import Liquiditaetsstatus from "../components/DashboardItems/Liquiditaetsstatus.vue";
import OPOS from "../components/DashboardItems/OPOS.vue";
import OPOSDebitoren from "../components/DashboardItems/OPOSDebitoren.vue";
import OPOSKreditoren from "../components/DashboardItems/OPOSKreditoren.vue";
import OPOSUebersichtstabelle from "../components/DashboardItems/OPOSUebersichtstabelle.vue";
import LiquiditaetsplanungTable from "../components/DashboardItems/LiquiditaetsplanungTable.vue";
import LiquiditaetsplanungChart from "../components/DashboardItems/LiquiditaetsplanungChart.vue";

export const getDashboardComponent = (id: string) => {
  switch (id) {
    case (BetriebsergebnisGesamt as any).id:
      return BetriebsergebnisGesamt;
    case (BetriebsergebnisGesamtJahre as any).id:
      return BetriebsergebnisGesamtJahre;
    case (ErfolgsrechnungTable as any).id:
      return ErfolgsrechnungTable;
    case (KostenGesamtVergleich as any).id:
      return KostenGesamtVergleich;
    case (KostenUndUmsaetze as any).id:
      return KostenUndUmsaetze;
    case (Kostenbereiche as any).id:
      return Kostenbereiche;
    case (Top10KundenBereichDrittUmsatz as any).id:
      return Top10KundenBereichDrittUmsatz;
    case (Top10KundenBereichFuenftUmsatz as any).id:
      return Top10KundenBereichFuenftUmsatz;
    case (Top10KundenBereichUmsatz as any).id:
      return Top10KundenBereichUmsatz;
    case (Top10KundenBereichViertUmsatz as any).id:
      return Top10KundenBereichViertUmsatz;
    case (Top10KundenBereichZweitUmsatz as any).id:
      return Top10KundenBereichZweitUmsatz;
    case (BetriebsergebnisVergleich as any).id:
      return BetriebsergebnisVergleich;
    case (Top10KundenUmsatz as any).id:
      return Top10KundenUmsatz;
    case (Top10Umsatzbereiche as any).id:
      return Top10Umsatzbereiche;
    case (Top10UmsatzerloeseBereiche as any).id:
      return Top10UmsatzerloeseBereiche;
    case (Top10UmsatzerloeseKunden as any).id:
      return Top10UmsatzerloeseKunden;
    case (Top5BereicheGesamt as any).id:
      return Top5BereicheGesamt;
    case (Top5Kostenbereiche as any).id:
      return Top5Kostenbereiche;
    case (Top5KostenbereichePieChart as any).id:
      return Top5KostenbereichePieChart;
    case (Top5UmsatzerloeseBereiche as any).id:
      return Top5UmsatzerloeseBereiche;
    case (Top5WareneinkaufGesamt as any).id:
      return Top5WareneinkaufGesamt;
    case (Umsatzerloese as any).id:
      return Umsatzerloese;
    case (BetriebswirtschaftlicheAuswertung as any).id:
      return BetriebswirtschaftlicheAuswertung;
    case (UmsatzerloeseVergleich as any).id:
      return UmsatzerloeseVergleich;
    case (Umsatzrentabilitaet as any).id:
      return Umsatzrentabilitaet;
    case (Umsatzwachstum as any).id:
      return Umsatzwachstum;
    case (WareneinkaufEtw as any).id:
      return WareneinkaufEtw;
    case (WareneinkaufGesamtPieChart as any).id:
      return WareneinkaufGesamtPieChart;
    case (EBITDAGesamt as any).id:
      return EBITDAGesamt;
    case (EBITDAGesamtJahre as any).id:
      return EBITDAGesamtJahre;
    case (EBITDAVergleich as any).id:
      return EBITDAVergleich;
    case (EBITGesamt as any).id:
      return EBITGesamt;
    case (EBITGesamtJahre as any).id:
      return EBITGesamtJahre;
    case (EBITVorlaeufigesErgebnis as any).id:
      return EBITVorlaeufigesErgebnis;
    case (BuchungssaetzeTable as any).id:
      return BuchungssaetzeTable;
    case (Liquiditaetsstatus as any).id:
      return Liquiditaetsstatus;
    case (OPOS as any).id:
      return OPOS;
    case (OPOSDebitoren as any).id:
      return OPOSDebitoren;
    case (OPOSKreditoren as any).id:
      return OPOSKreditoren;
    case (OPOSUebersichtstabelle as any).id:
      return OPOSUebersichtstabelle;
    case (LiquiditaetsplanungTable as any).id:
      return LiquiditaetsplanungTable;
    case (LiquiditaetsplanungChart as any).id:
      return LiquiditaetsplanungChart;

    default:
      return null;
  }
};

export const getDashboardProps = (id: string) => {
  const comp = getDashboardComponent(id) as any;
  if (comp) {
    return { id: comp.id, ...comp.dprops };
  } else {
    return null;
  }
};
