















import Widget from "@/components/common/Widget.vue";

import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";
import store from "@/store";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
  },
})
export default class OPOS extends Vue {
  public static id = "OPOS";
  public static dprops = { width: 4, height: 4, minWidth: 2, minHeight: 2 };
  get _id() {
    return OPOS.id;
  }
  get _dprops() {
    return OPOS.dprops;
  }
  get planSelected() {
    return store.getters.planSelected;
  }
}
