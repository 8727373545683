





















import Widget from "@/components/common/Widget.vue";
import VerticalLineAndBarGroupChart from "@/components/charts_js/VerticalLineAndBarGroupChart.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";
import store from "@/store";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    VerticalLineAndBarGroupChart,
  },
})
export default class UmsatzerloeseVergleich extends Vue {
  public static id = "UmsatzerloeseVergleich";
  public static dprops = { width: 4, height: 4, minWidth: 3, minHeight: 2 };
  get _id() {
    return UmsatzerloeseVergleich.id;
  }
  get _dprops() {
    return UmsatzerloeseVergleich.dprops;
  }
  get planSelected() {
    return store.getters.planSelected;
  }
}
