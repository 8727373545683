

















import Widget from "@/components/common/Widget.vue";
import VerticalStackBar from "@/components/charts/VerticalStackBar.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    VerticalStackBar,
  },
})
export default class Top5UmsatzerloeseBereiche extends Vue {
  public static id = "Top5UmsatzerloeseBereiche";
  public static dprops = { width: 4, height: 4, minWidth: 2, minHeight: 2 };
  get _id() {
    return Top5UmsatzerloeseBereiche.id;
  }
  get _dprops() {
    return Top5UmsatzerloeseBereiche.dprops;
  }
}
