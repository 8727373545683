<template>
  <component v-bind:is="dashboardItemComponent"></component>
</template>

<script>
import BetriebsergebnisGesamt from "./BetriebsergebnisGesamt.vue";
import BetriebsergebnisGesamtJahre from "./BetriebsergebnisGesamtJahre.vue";
import ErfolgsrechnungTable from "./ErfolgsrechnungTable.vue";
import KostenGesamtVergleich from "./KostenGesamtVergleich.vue";
import KostenUndUmsaetze from "./KostenUndUmsaetze.vue";
import Kostenbereiche from "./Kostenbereiche.vue";
import Top10KundenBereichDrittUmsatz from "./Top10KundenBereichDrittUmsatz.vue";
import Top10KundenBereichFuenftUmsatz from "./Top10KundenBereichFuenftUmsatz.vue";
import Top10KundenBereichUmsatz from "./Top10KundenBereichUmsatz.vue";
import Top10KundenBereichViertUmsatz from "./Top10KundenBereichViertUmsatz.vue";
import Top10KundenBereichZweitUmsatz from "./Top10KundenBereichZweitUmsatz.vue";
import BetriebsergebnisVergleich from "./BetriebsergebnisVergleich.vue";
import Top10KundenUmsatz from "./Top10KundenUmsatz.vue";
import Top10Umsatzbereiche from "./Top10Umsatzbereiche.vue";
import Top10UmsatzerloeseBereiche from "./Top10UmsatzerloeseBereiche.vue";
import Top10UmsatzerloeseKunden from "./Top10UmsatzerloeseKunden.vue";
import Top5BereicheGesamt from "./Top5BereicheGesamt.vue";
import Top5Kostenbereiche from "./Top5Kostenbereiche.vue";
import Top5KostenbereichePieChart from "./Top5KostenbereichePieChart.vue";
import Top5UmsatzerloeseBereiche from "./Top5UmsatzerloeseBereiche.vue";
import Top5WareneinkaufGesamt from "./Top5WareneinkaufGesamt.vue";
import Umsatzerloese from "./Umsatzerloese.vue";
import BetriebswirtschaftlicheAuswertung from "./BetriebswirtschaftlicheAuswertung.vue";
import UmsatzerloeseVergleich from "./UmsatzerloeseVergleich.vue";
import Umsatzrentabilitaet from "./Umsatzrentabilitaet.vue";
import Umsatzwachstum from "./Umsatzwachstum.vue";
import WareneinkaufEtw from "./WareneinkaufEtw.vue";
import WareneinkaufGesamtPieChart from "./WareneinkaufGesamtPieChart.vue";
import EBITDAGesamt from "./EBITDAGesamt.vue";
import EBITDAGesamtJahre from "./EBITDAGesamtJahre.vue";
import EBITDAVergleich from "./EBITDAVergleich.vue";
import EBITGesamt from "./EBITGesamt.vue";
import EBITGesamtJahre from "./EBITGesamtJahre.vue";
import EBITVorlaeufigesErgebnis from "./EBITVorlaeufigesErgebnis.vue";
import BuchungssaetzeTable from "./BuchungssaetzeTable.vue";
import Liquiditaetsstatus from "./Liquiditaetsstatus.vue";
import OPOS from "./OPOS.vue";
import OPOSDebitoren from "./OPOSDebitoren.vue";
import OPOSKreditoren from "./OPOSKreditoren.vue";
import OPOSUebersichtstabelle from "./OPOSUebersichtstabelle.vue";
import LiquiditaetsplanungTable from "./LiquiditaetsplanungTable.vue";
import LiquiditaetsplanungChart from "./LiquiditaetsplanungChart.vue";

import { getDashboardComponent } from "../../helper/dashboardUtils";

export default {
  components: {
    BetriebsergebnisGesamt,
    BetriebsergebnisGesamtJahre,
    ErfolgsrechnungTable,
    KostenGesamtVergleich,
    KostenUndUmsaetze,
    Kostenbereiche,
    Top10KundenBereichDrittUmsatz,
    Top10KundenBereichFuenftUmsatz,
    Top10KundenBereichUmsatz,
    Top10KundenBereichViertUmsatz,
    Top10KundenBereichZweitUmsatz,
    BetriebsergebnisVergleich,
    Top10KundenUmsatz,
    Top10Umsatzbereiche,
    Top10UmsatzerloeseBereiche,
    Top10UmsatzerloeseKunden,
    Top5BereicheGesamt,
    Top5Kostenbereiche,
    Top5KostenbereichePieChart,
    Top5UmsatzerloeseBereiche,
    Top5WareneinkaufGesamt,
    Umsatzerloese,
    BetriebswirtschaftlicheAuswertung,
    UmsatzerloeseVergleich,
    Umsatzrentabilitaet,
    Umsatzwachstum,
    WareneinkaufEtw,
    WareneinkaufGesamtPieChart,
    EBITDAGesamt,
    EBITDAGesamtJahre,
    EBITDAVergleich,
    EBITGesamt,
    EBITGesamtJahre,
    EBITVorlaeufigesErgebnis,
    BuchungssaetzeTable,
    Liquiditaetsstatus,
    OPOS,
    OPOSDebitoren,
    OPOSKreditoren,
    OPOSUebersichtstabelle,
    LiquiditaetsplanungTable,
    LiquiditaetsplanungChart,
  },
  inject: { $item: { default: null } },
  computed: {
    itemByinject() {
      if (this.$item) {
        return this.$item();
      }
      return null;
    },
    id() {
      if (this.itemByinject) {
        return this.itemByinject.id;
      }
      return "";
    },
    dashboardItemComponent() {
      return getDashboardComponent(this.id);
    },
  },
};
</script>

<style></style>
