












import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import NoDataHtml from "@/components/common/NoDataHtml.vue";
import { combineMonthToQuarter } from "@/helper/charts";
import store from "@/store";
import { nFormatter } from "@/helper/utils";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { warn } from "vue-class-component/lib/util";

const labels = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];

const scales = {
  x: {
    grid: {
      display: false,
      borderColor: "transparent",
    },
  },
  y: {
    ticks: {
      callback: function(v: any) {
        return nFormatter(v, 0);
      },
    },
    grid: {
      display: true,
      borderDash: [8, 4],
      borderColor: "transparent",
    },
  },
};

const datalabels = {
  labels: {
    start: {
      anchor: "start",
      align: "bottom",
      offset: 4,
      formatter: (v: any) => (v >= 0 ? "" : nFormatter(v, 0)),
    },
    end: {
      anchor: "end",
      align: "top",
      offset: 4,
      formatter: (v: any) => (v <= 0 ? "" : nFormatter(v, 0)),
    },
  },
};

@Component({ components: { NoDataHtml } })
export default class VerticalLineAndBarGroupChart extends Vue {
  @Prop() id!: string;
  @Prop() dataId?: string;
  @Prop() legend1!: string;
  @Prop() legend2!: string;
  @Prop() data?: { d0: any; d1: any };

  noDataFound: Boolean = false;
  chart: any;

  get testData() {
    return this.data;
  }

  get chartData() {
    return {
      data: store.getters.currentCalcs,
      prevData: store.getters.previousCalcs,
      rangeStep: store.getters.rangeStep,
    };
  }
  get themeType() {
    return store.getters.themeType;
  }
  get chartType() {
    return store.getters.chartType;
  }

  mounted() {
    this.initChart();
  }

  @Watch("chartData")
  @Watch("themeType")
  @Watch("chartType")
  initChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    if (this.data) {
      if (store.getters.rangeStep === "quarterly") {
        this.renderChart(
          Object.values<number>(this.data.d0),
          Object.values<number>(this.data.d1),
          Object.keys(this.data.d1).map((v) => v.substring(5))
        );
      } else {
        this.renderChart(
          Object.values<number>(this.data.d0),
          Object.values<number>(this.data.d1),
          labels
        );
      }
    } else {
      const d0 = this.composeData(
        store.getters.currentCalcs ? store.getters.currentCalcs.BWA : [],
        this.dataId
      );
      const d1 = this.composeData(
        store.getters.previousCalcs ? store.getters.previousCalcs.BWA : [],
        this.dataId
      );

      if (!Object.keys(d0).length && !Object.keys(d1).length) {
        this.noDataFound = true;
        return;
      }
      this.noDataFound = false;

      if (store.getters.rangeStep === "quarterly") {
        this.renderChart(
          Object.values<number>(d0),
          Object.values<number>(d1),
          Object.keys(d0).map((v) => v.substring(5))
        );
      } else {
        this.renderChart(
          Object.values<number>(d0),
          Object.values<number>(d1),
          labels
        );
      }
    }
  }

  renderChart(d0: number[], d1: number[], labels: string[]) {
    const style = getComputedStyle(document.body);
    const primary = style.getPropertyValue("--primary");
    const primaryLight = style.getPropertyValue("--primary-light");

    const ctx = window.document.getElementById(this.id)! as any;

    this.chart = new Chart(ctx, {
      type: this.chartType,
      plugins: [ChartDataLabels],
      data: {
        labels: labels,
        datasets: [
          {
            label: this.legend2,
            borderRadius: 4,
            data: d1,
            borderColor: primaryLight,
            backgroundColor: primaryLight,
          },
          {
            label: this.legend1,
            borderRadius: 4,
            data: d0,
            borderColor: primary,
            backgroundColor: primary,
          },
        ],
      },

      options: {
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: datalabels as any,
        },
        scales: {
          ...scales,
        },
      },
    });
  }

  composeData(data: any, key: any) {
    if (!data) {
      return [];
    }
    if (Object.keys(data).length === 0) {
      return [];
    }
    var result: any = [];
    for (var i = 1; i < 13; i++) {
      var k =
        (i < 10 ? "0" + i : i) +
        "." +
        (store.getters.selectedYear && store.getters.selectedYear[0])
          .toString()
          .substr(2);
      result[k] = data[i][key];
    }
    if (store.getters.rangeStep === "quarterly") {
      result = combineMonthToQuarter(result);
    }
    return result;
  }
}
