
























































import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import { abbreviate } from "@/helper/charts";
import NoDataHtml from "@/components/common/NoDataHtml.vue";
import store from "@/store";

declare const d3: any;
@Component({ components: { NoDataHtml } })
export default class SmallPie extends Vue {
  @Prop() id!: string;
  @Prop() dataId!: string;
  //initial data
  storeData: any = null;
  showArrows: Boolean = false;
  showDetails: Boolean = false;
  noDataFound: Boolean = false;

  chartDetails: any = {
    order: "",
    type: "",
    value: "",
  };

  get chartData() {
    return {
      lief: store.getters.lieferantenTop,
      data: store.getters.currentCalcs,
      resize: store.getters.resize,
    };
  }

  mounted() {
    this.onDataChange();
  }

  @Watch("chartData")
  onDataChange() {
    this.drawSmallPie(this.id, this.dataId);
  }

  setDetails(order: any, type: any, value: any) {
    this.chartDetails.order = order;
    this.chartDetails.type = type;
    this.chartDetails.value = value;
  }
  drawSmallPie(id: any, dataId: any) {
    if (id === "chart_material_top5_pie") {
      this.storeData = store.getters.lieferantenTop;
    } else {
      this.storeData =
        store.getters.currentCalcs && store.getters.currentCalcs[dataId];
    }
    if (!this.storeData) {
      this.showArrows = false;
      this.noDataFound = true;
      return;
    }
    var data: any = [];
    if (this.storeData["total"].length <= 0) {
      let element: any = document.getElementById(id);
      element.innerHTML = "";
      this.showArrows = false;
      this.noDataFound = true;
      this.storeData = null;
      return;
    }
    if (this.storeData["total"].length) {
      for (var i = 0; i < this.storeData["total"].length; i++) {
        data.push({
          Species: this.storeData["total"][i][0],
          Probability: this.storeData["total"][i][1],
          Value: Math.abs(this.storeData["total"][i][2]),
        });
      }
      this.showArrows = true;
      this.noDataFound = false;
      this.showDetails = true;
      setTimeout(() => {
        this.donutChartSmallWidget(id, data);
      });
    } else {
      this.showDetails = false;
      this.showArrows = false;
      this.noDataFound = true;
    }
  }

  donutChartSmallWidget(id: any, d: any) {
    const doc = document as any;
    doc.getElementById(id).innerHTML = "";
    if (!d3.selectAll("#" + id).node()) {
      return;
    }
    if (d.length > 1) {
      this.showArrows = true;
    }
    var size = d3.min([
      160,
      d3
        .selectAll("#" + id)
        .node()
        .getBoundingClientRect().width,
    ]);
    var donut = this.donutChart()
      .width(size)
      .height(size)
      .cornerRadius(3)
      .padAngle(0.015)
      .variable("Value")
      .category("Species")
      .selectedId(id)
      .big(true)
      .small(true);
    d.sort(function(a: any, b: any) {
      return b.Value - a.Value;
    });
    d3.select("#" + id)
      .datum(d)
      .call(donut);
  }

  donutChart() {
    var width: any,
      height: any,
      margin = { top: 10, right: 10, bottom: 10, left: 10 },
      colour = d3.scaleOrdinal(d3.schemeCategory10),
      variable: any,
      category: any,
      padAngle: any,
      radius: any,
      floatFormat = d3.format(".4r"),
      cornerRadius: any,
      percentFormat = d3.format(",.2%"),
      selectedId: any,
      selectedIndex = -1,
      big = false,
      small = false;

    const chart = (selection: any): any => {
      selection.each((data: any, key: any) => {
        // generate chart
        var radius = Math.min(width, height) / 2;
        // ===========================================================================================

        // creates a new pie generator
        var pie: any = (d3.pie() as any)
          .value(function(d: any) {
            return floatFormat(d[variable]);
          })
          .sort(null);
        // contructs and arc generator. This will be used for the donut. The difference between outer and inner
        // radius will dictate the thickness of the donut
        var arc = d3
          .arc()
          .outerRadius(radius * 0.8)
          .innerRadius(radius * 0.5)
          .cornerRadius(cornerRadius)
          .padAngle(padAngle);

        // this arc is used for aligning the text labels
        var outerArc = d3
          .arc()
          .outerRadius(radius * 0.7)
          .innerRadius(radius * 0.6);
        // ===========================================================================================

        // ===========================================================================================
        // append the svg object to the selection
        var svg = selection
          .append("svg")
          .attr("width", width)
          .attr("height", height)
          .append("g")
          .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
        // ===========================================================================================

        // ===========================================================================================
        // g elements to keep elements within svg modular
        svg.append("g").attr("class", "lines");
        svg.append("g").attr("class", "slices");
        svg.append("g").attr("class", "labelName");

        var temp = [
          {
            Probability: "",
            Species: "",
            Value: 1,
          },
        ];
        var temp_path = svg
          .select(".lines")
          .datum(temp)
          .selectAll("path")
          .data(pie)
          .enter()
          .append("path")
          .attr("fill", "var(--primary-lighter)")
          .attr("d", outerArc);
        // ===========================================================================================

        // ===========================================================================================
        // add and colour the donut slices
        var paths = svg
          .select(".slices")
          .datum(data)
          .selectAll("path")
          .data(pie)
          .enter();
        var path: any = paths
          .append("path")
          .attr("fill", "var(--primary-light)")
          .attr("d", arc)
          .attr("class", function(d: any, i: any) {
            return selectedId + "-" + i;
          })
          .on("mouseover", (d: any, i: any) => handleMouseOver(d, i, path))
          .on("mouseleave", (d: any, i: any) => handleMouseOut(d, i, path));

        var tip = d3
          .tip()
          .attr("class", "d3-tip")
          .direction(function(d: any) {
            if (d.startAngle > 4.7) {
              return "n";
            } else if (d.startAngle > 3.1) {
              return "s";
            } else if (d.startAngle > 1.57) {
              return "s";
            }
            return "n";
          })
          .offset(function(d: any) {
            const radio = width / 500;
            if (d.startAngle > 4.7) {
              return [-150, -200];
            } else if (d.startAngle > 3.1) {
              return [30, -200];
            } else if (d.startAngle > 1.57) {
              return [30, -200];
            }
            return [-150, -200];
          })
          .html(function(d: any) {
            var className = big ? "d-none" : "";
            var content =
              '<div id="donut_tip" class="donut-tip ' +
              className +
              '">' +
              '<div class="donut-tip-title">' +
              d.data.Probability +
              "</div>" +
              '<div class="donut-tip-body">' +
              '<div class="donut-tip-item">' +
              "<span>Betrag</span>" +
              '<span class="donut-tip-value">' +
              abbreviate(d.data.Value, 1, false, false) +
              "</span>" +
              "</div>" +
              "</div>" +
              "</div>";
            return content;
          });

        svg.call(tip);

        var pathAnim = function(path: any, dir: any) {
          switch (dir) {
            case 0:
              path.transition().attr(
                "d",
                d3
                  .arc()
                  .outerRadius(radius * 0.85)
                  .innerRadius(radius * 0.45)
                  .cornerRadius(cornerRadius)
                  .padAngle(padAngle)
              );
              break;

            case 1:
              path.transition().attr(
                "d",
                d3
                  .arc()
                  .outerRadius(radius * 0.8)
                  .innerRadius(radius * 0.5)
                  .cornerRadius(cornerRadius)
                  .padAngle(padAngle)
              );
              break;
          }
        };

        // Initial showing the detail
        this.showDetails = true;
        this.setDetails(
          toolTipHTML(0),
          data[0]["Probability"],
          abbreviate(data[0]["Value"], 1, false, false)
        );
        const doc = document as any;
        // clicking left arrow
        doc
          .querySelector("#" + selectedId + "_left_arrow")
          .addEventListener("click", function() {
            if (selectedIndex < 1) {
              selectedIndex = data.length - 1;
            } else {
              selectedIndex--;
            }
            arrowClick(selectedId, selectedIndex);
          });
        // clicking right arrow
        doc
          .querySelector("#" + selectedId + "_right_arrow")
          .addEventListener("click", function() {
            if (selectedIndex === data.length - 1) {
              selectedIndex = 0;
            } else {
              selectedIndex++;
            }
            arrowClick(selectedId, selectedIndex);
          });

        const arrowClick = (selectedId: any, selectedIndex: any) => {
          clearSelectedPaths();
          pathAnim(d3.select("." + selectedId + "-" + selectedIndex), 0);
          d3.select("." + selectedId + "-" + selectedIndex).style(
            "fill",
            "var(--primary)"
          );
          showInnerTip(selectedIndex);

          this.showDetails = true;
          this.setDetails(
            toolTipHTML(selectedIndex),
            data[selectedIndex]["Probability"],
            abbreviate(data[selectedIndex]["Value"], 1, false, false)
          );
        };

        function clearSelectedPaths() {
          for (var i = 0; i < data.length; i++) {
            pathAnim(d3.select("." + selectedId + "-" + i), 1);
            d3.select("." + selectedId + "-" + i).style(
              "fill",
              "var(--primary-light)"
            );
          }
        }

        const showDetail = (d: any) => {
          this.showDetails = true;
          this.setDetails(
            toolTipHTML(d.index),
            d.data.Probability,
            abbreviate(d.data.Value, 1, false, false)
          );
        };

        const disableDetail = () => {
          this.showDetails = false;
        };

        function handleMouseOver(d: any, i: any, path: any) {
          if (big) {
            disableInnerTip(true);
          }
          clearSelectedPaths();
          d3.select(path._groups[0][i]).style("fill", "var(--primary)");
          pathAnim(d3.select(path._groups[0][i]), 0);
          tip.show(d);
          showInnerTip(d.index);
          showDetail(d);
          selectedIndex = d.index;
        }

        function handleMouseOut(d: any, i: any, path: any) {
          tip.hide(d);
          if (big) {
            return;
          }
          clearSelectedPaths();
          d3.select(path._groups[0][i]).style("fill", "var(--primary-light)");
          pathAnim(d3.select(path._groups[0][i]), 1);
          disableInnerTip(false);
          disableDetail();
        }
        // ===========================================================================================

        // calculates the angle for the middle of a slice
        function midAngle(d: any) {
          return d.startAngle + (d.endAngle - d.startAngle) / 2;
        }

        // function that creates and adds the tool tip to a selected element
        function showInnerTip(index: any) {
          svg
            .append("circle")
            .attr("class", "toolCircle" + (big ? "" : " small"))
            .attr("r", radius * 0.3)
            .style("fill", "var(--primary)");

          svg
            .append("text")
            .attr("class", "toolCircle" + (big ? "" : " small"))
            .attr("dy", small ? 5 : 10)
            .text(toolTipHTML(index))
            .style("font-size", ajustFontSize())
            .style("font-weight", "bold")
            .style("text-anchor", "middle")
            .style("fill", "#ffffff");
        }

        function disableInnerTip(all: any) {
          var cl = ".toolCircle";
          if (!all) {
            cl += ".small";
          }
          svg.selectAll(cl).remove();
        }

        function ajustFontSize() {
          if (Math.min(width, height) > 450) {
            return "3em";
          } else if (
            Math.min(width, height) <= 450 &&
            Math.min(width, height) > 200
          ) {
            return "2em";
          }
          return "14px";
        }

        // function to create the HTML string for the tool tip. Loops through each key in data object
        // and returns the html string key: value
        function toolTipHTML(i: any) {
          var index = i + 1;
          var tip = "";
          if (index < 10) {
            tip = "0" + index.toString();
          } else {
            tip = index.toString();
          }
          return tip;
        }
        // ===========================================================================================
        if (key === 0 && big) {
          arrowClick(selectedId, 0);
        }
      });
    };

    chart.width = function(value: any) {
      if (!arguments.length) return width;
      width = value;
      return chart;
    };

    chart.height = function(value: any) {
      if (!arguments.length) return height;
      height = value;
      return chart;
    };

    chart.margin = function(value: any) {
      if (!arguments.length) return margin;
      margin = value;
      return chart;
    };

    chart.radius = function(value: any) {
      if (!arguments.length) return radius;
      radius = value;
      return chart;
    };

    chart.padAngle = function(value: any) {
      if (!arguments.length) return padAngle;
      padAngle = value;
      return chart;
    };

    chart.cornerRadius = function(value: any) {
      if (!arguments.length) return cornerRadius;
      cornerRadius = value;
      return chart;
    };

    chart.colour = function(value: any) {
      if (!arguments.length) return colour;
      colour = value;
      return chart;
    };

    chart.variable = function(value: any) {
      if (!arguments.length) return variable;
      variable = value;
      return chart;
    };

    chart.category = function(value: any) {
      if (!arguments.length) return category;
      category = value;
      return chart;
    };

    chart.selectedId = function(value: any) {
      if (!arguments.length) return selectedId;
      selectedId = value;
      return chart;
    };

    chart.big = function(value: any) {
      if (!arguments.length) return big;
      big = value;
      return chart;
    };

    chart.small = function(value: any) {
      if (!arguments.length) return small;
      small = value;
      return chart;
    };
    return chart;
  }
}
