








































































import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import NoDataHtml from "@/components/common/NoDataHtml.vue";
import {
  combineMonthToQuarter,
  changeDateFormat,
  getMonthText,
  topRoundedRect,
} from "@/helper/charts";
import store from "@/store";
import lodash from "lodash";

declare const d3: any;

@Component({ components: { NoDataHtml } })
export default class VerticalStackBar extends Vue {
  @Prop() id!: string;
  @Prop() dataId!: string;

  stackChart: any = {};
  noDataFound: Boolean = false;

  get chartData() {
    return {
      data: store.getters.currentCalcs,
      rangeStep: store.getters.rangeStep,
      resize: store.getters.resize,
    };
  }

  mounted() {
    this.onDataChange();
  }

  @Watch("chartData")
  onDataChange() {
    if (this.chartData.data && this.chartData.rangeStep) {
      let data = lodash.cloneDeep(this.chartData.data[this.dataId]);
      this.drawVerticalStackBarByPeriod(data, this.id);
    } else {
      this.noDataFound = true;
    }
  }

  drawVerticalStackBarByPeriod(data: any, id: any) {
    let chartData =
      store.getters.rangeStep === "quarterly"
        ? combineMonthToQuarter(data)
        : changeDateFormat(data);
    this.chartVerticalStackBar(chartData, id);
  }

  chartVerticalStackBar(d: any, id: any) {
    if (!d || d.sumtotal == 0) {
      this.noDataFound = true;
      return;
    }
    this.noDataFound = false;
    let el: any = document.getElementById(id);
    this.stackChart = {};
    this.stackChart.svg_width = el.clientWidth;
    this.stackChart.svg_height = el.clientHeight;
    var margin = { top: 20, right: 20, bottom: 20, left: 45 };
    var width = el.clientWidth - margin.left - margin.right;
    var height = el.clientHeight - margin.top - margin.bottom;
    this.stackChart.transform = `translate(${margin.left}, ${margin.top})`;
    var x: any = d3
      .scaleBand()
      .rangeRound([0, width])
      .paddingInner(0.7)
      .align(0.1);
    var y: any = d3.scaleLinear().rangeRound([height, 0]);
    var colors: any[] = [
      "var(--primary)",
      "var(--primary-lighter-20)",
      "var(--primary-lighter-40)",
      "var(--primary-lighter-60)",
      "var(--primary-lighter-80)",
    ];
    var data: any[] = [];
    var maxVal: any = 0;
    var keys: any = Object.keys(d);

    for (var i = 0; i < keys.length; i++) {
      if (d[keys[i]] && typeof d[keys[i]] === "object" && keys[i] !== "total") {
        var total = 0;
        for (var j = 0; j < d[keys[i]].length; j++) {
          if (d[keys[i]][j][2] < 0) {
            d[keys[i]][j][2] = 0;
          }
          if (d[keys[i]][j][2]) {
            total += d[keys[i]][j][2];
          }
          if (typeof d[keys[i]][j] === "object") {
            d[keys[i]][j][3] = getMonthText(keys[i]);
            d[keys[i]][j][4] = j;
            d[keys[i]][j][5] = total;
          }
        }
        if (total > maxVal) {
          maxVal = total;
        }
        data.push({ month: getMonthText(keys[i]), data: d[keys[i]] });
      }
    }
    x.domain(
      data.map(function(d: any) {
        return d.month;
      })
    );
    y.domain([0, maxVal]).nice();

    this.stackChart.g_groups = [];
    this.stackChart.paths = [];
    this.stackChart.texts = [];
    this.stackChart.tips = [];
    let tip: any = [];
    data.forEach((item: any, index: any) => {
      let total_value: any = 0;
      this.stackChart.g_groups.push({ paths: [] });
      this.stackChart.paths.push({
        path_d:
          item.data.length > 0 && y(item.data[0][5])
            ? topRoundedRect(
                x(item.month),
                y(item.data[0][5]),
                x.bandwidth(),
                height,
                4
              )
            : "",
      });
      tip = [];
      item.data.forEach((path: any) => {
        if (path[2]) {
          tip.push({
            color: colors[path[4]],
            label: path[1],
            value: (path[2] / 1000).toFixed() + "k",
          });
        }
        if (path[2]) {
          total_value += path[2];
        }
        this.stackChart.g_groups[index].paths.push({
          path_d: path[2]
            ? topRoundedRect(
                x(path[3]),
                y(path[5]),
                x.bandwidth(),
                height > 0 ? height - y(path[5]) : 0,
                4
              )
            : null,
          fill: colors[path[4]],
        });
      });
      if (tip.length > 0) {
        this.stackChart.tips.push(tip);
      }
      this.stackChart.texts.push({
        value: (total_value / 1000).toFixed() + "k",
        text_x: x(item.month) + x.bandwidth() / 2,
        text_y: y(total_value) - 5,
      });
    });
    setTimeout(() => {
      d3.select("#" + id + "_axisY")
        .attr("class", "axis axis-y")
        .attr("transform", "translate(-10, 0)")
        .call(
          d3
            .axisLeft(y)
            .ticks(5, "s")
            .tickSize(-width)
        );
      d3.select("#" + id + "_axisX")
        .attr("class", "axis axis-x")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));
    });
  }
}
