


















import Widget from "@/components/common/Widget.vue";
import StaticTable from "@/components/table/StaticTable.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";
import store from "@/store";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    StaticTable,
  },
})
export default class Top10Umsatzbereiche extends Vue {
  public static id = "Top10Umsatzbereiche";
  public static dprops = { width: 4, height: 4, minWidth: 2, minHeight: 2 };
  get _id() {
    return Top10Umsatzbereiche.id;
  }
  get _dprops() {
    return Top10Umsatzbereiche.dprops;
  }
  get umsatze() {
    let umsatzeHead: any = [];
    store.watch(
      (getter) => {
        if (getter.previousCalcs && (getter.previousCalcs as any).UMS_TOP10) {
          umsatzeHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
            {
              title: "Vergleich",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0] - 1,
              class: "text-center",
            },
            {
              title: "Abweichung",
              description: "Ist/Referenz",
              class: "text-center",
              colspan: 2,
            },
          ];
        } else {
          umsatzeHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
          ];
        }
      },
      (getters) => {}
    );
    return {
      head: umsatzeHead,
      body: {
        prev:
          store.getters.previousCalcs && store.getters.previousCalcs.UMS_TOP10,
        current:
          store.getters.currentCalcs && store.getters.currentCalcs.UMS_TOP10,
      },
    };
  }
}
