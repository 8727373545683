































import Vue from "vue";
import lodash from "lodash";
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import DashboardItemManager from "@/components/DashboardItems/DashboardItemManager.vue";
import Component from "vue-class-component";
import store from "@/store";
import api from "@/api";
import { Watch } from "vue-property-decorator";
import { bwaRenderChartUmsatzTop10 } from "@/helper/charts";
import TopBar from "@/components/common/TopBar.vue";
import {
  callCalc,
  callLieferantentop5,
  callMaterialdetail,
  callPlan,
  callPrevCalc,
  callPrevYearsums,
  callYearsums,
} from "@/helper/utils";
import { oposBarCharts, chartLiquiditatsstatus } from "../../helper/charts";

@Component({
  components: {
    TopBar,
    Dashboard,
    DashLayout,
    DashItem,
    DashboardItemManager,
  },
})
export default class DashboardPage extends Vue {
  onItemChange() {
    store.dispatch("updateResize", window.innerWidth + 1);
    setTimeout(() => {
      store.dispatch("updateResize", window.innerWidth);
      api.saveUserSettings();
    }, 300);
  }

  initPage = false;
  get dlayouts() {
    return store.getters.dlayout;
  }

  get showNoItemsMessage() {
    return store.getters.dlayout[0].items.length == 0;
  }

  get prevCalcs() {
    return store.getters.previousCalcs;
  }

  get currentCalcs() {
    return store.getters.currentCalcs;
  }

  get chartData() {
    return {
      data: store.getters.currentCalcs,
      prevData: store.getters.previousCalcs,
    };
  }

  get resize() {
    return {
      resize: store.getters.resize,
    };
  }

  get calculationPlan() {
    return {
      data: store.getters.currentCalcs,
    };
  }

  get topBarData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  get topBarNavigation() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
      rangeStep: store.getters.rangeStep,
      comparison: store.getters.planSelected,
    };
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  get watchData() {
    return {
      client: store.getters.selectedClient,
      year: store.getters.selectedYear,
    };
  }

  get oposData() {
    return {
      deb: store.getters.oposDeb,
      kred: store.getters.oposKred,
      resize: store.getters.resize,
    };
  }

  get curves() {
    return {
      curves: store.getters.oposCurves,
      resize: store.getters.resize,
    };
  }

  loadFromPlan() {
    let client = JSON.parse(String(localStorage.getItem("client")));
    let year = JSON.parse(String(localStorage.getItem("year")));

    api
      .getPlanData(client, year)
      .then((res: any) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        let prevData = null;
        if (this.prevCalcs) {
          prevData = lodash.cloneDeep(this.prevCalcs);
        } else {
          prevData = { BWA: lodash.cloneDeep(this.currentCalcs["BWA"]) };
        }
        if (res && res.data) {
          if (prevData) {
            prevData["BWA"] = res.data.plan_month;
          }
        } else {
          if (prevData) {
            const l = prevData["BWA"]["legend"];
            for (var i = 0; i < l.length; i++) {
              prevData["BWA"]["total"][l[i][0]] = 0;
              for (var month = 1; month < 13; month++) {
                prevData["BWA"][month][l[i][0]] = 0;
              }
            }
          }
        }
        store.dispatch("updatePreviousCalcs", prevData);
      })
      .catch((err) => {
        store.dispatch("updatePreviousCalcs", null);
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  pushStateHistory(
    stb: any,
    mandant: any,
    year: any,
    range: any,
    comparison: any
  ) {
    const selectedStb = this.biAgency != null ? this.biAgency : stb;
    let path =
      "/" +
      this.$route.name +
      "/" +
      encodeURIComponent(selectedStb) +
      "/" +
      encodeURIComponent(mandant) +
      "/" +
      encodeURIComponent(year) +
      "/" +
      encodeURIComponent(range) +
      "/" +
      encodeURIComponent(comparison);

    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (this.topBarData.year && this.topBarData.client) {
      if (!store.getters.isCalcCalled) {
        callCalc();
      }
      if (!store.getters.isPrevCalcCalled && !store.getters.planSelected) {
        callPrevCalc();
      }
      if (store.getters.planSelected) {
        callPlan(store.getters.selectedYear[0]);
      }
      if (!store.getters.isLieferantentop5Called) {
        callLieferantentop5({});
      }
      if (!store.getters.isMaterialdetailCalled) {
        callMaterialdetail({});
      }
      if (!store.getters.isYearsumsCalled) {
        callYearsums();
      }
      if (!store.getters.isPrevYearsumsCalled) {
        callPrevYearsums();
      }

      if (this.initPage) {
        this.onTopBarNavigationChange();
        this.initPage = false;
      }
    }
  }

  @Watch("topBarNavigation")
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar
    let mandant: string =
      this.topBarNavigation.client && this.topBarNavigation.client[1];
    let stb: string =
      this.topBarNavigation.client && this.topBarNavigation.client[0];
    let year: string =
      this.topBarNavigation.year && this.topBarNavigation.year[0];
    if (mandant && year) {
      this.pushStateHistory(
        stb,
        mandant,
        year,
        store.getters.rangeStep,
        store.getters.planSelected ? "planjahr" : "vorjahr"
      );
    }
  }

  @Watch("chartData")
  drawCharts() {
    bwaRenderChartUmsatzTop10();
    this.getDrilldownBwaChildren();
  }

  @Watch("resize")
  onResize() {
    bwaRenderChartUmsatzTop10();
  }

  @Watch("calculationPlan")
  loadPlan() {
    let initPlan = JSON.parse(String(localStorage.getItem("initPlan")));
    if (initPlan) {
      this.loadFromPlan();
      localStorage.setItem("initPlan", JSON.stringify(false));
    }
  }

  getDrilldownBwaChildren() {
    if (this.chartData.data && this.chartData.data.BWA) {
      let ids: any = [];
      this.chartData.data.BWA.legend.forEach((l: any) => {
        ids.push(l[0]);
      });
      if (this.topBarData.client && this.topBarData.year) {
        store.dispatch(
          "updateLoading",
          store.state.loading >= 0 ? store.state.loading + 1 : 1
        );
        api
          .getDrilldownBwaChildren(
            this.topBarData.client,
            this.topBarData.year,
            ids
          )
          .then((res) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            store.dispatch("updateBwaLinkStatus", res.data);
          })
          .catch((err) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            store.dispatch("updateBwaLinkStatus", null);
          });
      }
    }
  }

  mounted() {
    this.initPage = true;

    this.onDataChange();
    this.onOposDataChange();
    this.onCurvesChange();

    store.dispatch("updateResize", window.innerWidth + 1);
    setTimeout(() => {
      store.dispatch("updateResize", window.innerWidth);
    }, 300);

    this.onTopBarDataChange();
    this.drawCharts();
    if (localStorage.getItem("from") == "bwa_big_table") {
      (document.getElementById("bwa_table_auswertung") as any).scrollIntoView();
      localStorage.removeItem("from");
    } else if (localStorage.getItem("from") == "bwa_table") {
      (document.getElementById("bwa_table") as any).scrollIntoView();
      localStorage.removeItem("from");
    }
  }

  @Watch("oposData")
  onOposDataChange() {
    if (this.oposData.deb && this.oposData.kred) {
      oposBarCharts(this.oposData.deb, this.oposData.kred);
    }
  }

  @Watch("curves")
  onCurvesChange() {
    if (this.curves.curves) {
      chartLiquiditatsstatus(this.curves.curves, 90);
    }
  }

  setRange(e: any) {
    chartLiquiditatsstatus(this.curves.curves, e);
  }

  @Watch("watchData")
  onDataChange() {
    if (this.watchData.client && this.watchData.year) {
      //store.dispatch("updateLoading", store.state.loading + 3);

      api
        .getOposDeb(this.watchData.client, this.watchData.year)
        .then((res) => {
          store.dispatch("updateOposDeb", res.data);
          //store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateOposDeb", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });

      api
        .getOposKred(this.watchData.client, this.watchData.year)
        .then((res) => {
          store.dispatch("updateOposKred", res.data);
          //store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateOposKred", null);
          //store.dispatch("updateLoading", store.state.loading - 1);
        });

      /// DUPLICATE CALL OF GET OPPOS TABLE
      // api.getOposFilters(this.watchData.client, this.watchData.year).then(res => {
      //   store.dispatch('updateOposFilters', res.data)
      //   store.dispatch('updateLoading', store.state.loading - 1)
      // }).catch(err => {
      //   store.dispatch('updateOposFilters', null)
      //   store.dispatch('updateLoading', store.state.loading - 1)
      // })
      api
        .getOposCurves(this.watchData.client, this.watchData.year)
        .then((res) => {
          store.dispatch("updateOposCurves", res.data);
          //store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateOposCurves", null);
          //store.dispatch("updateLoading", store.state.loading - 1);
        });

      if (this.initPage) {
        this.onTopBarNavigationChange();
        this.initPage = false;
      }
    }
  }
}
