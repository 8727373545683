







import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import NoDataHtml from "@/components/common/NoDataHtml.vue";
import { combineMonthToQuarter } from "@/helper/charts";
import store from "@/store";
import Chart from "chart.js/auto";
import { nFormatter } from "@/helper/utils";
import ChartDataLabels from "chartjs-plugin-datalabels";

const datalabels = {
  labels: {
    start: {
      anchor: "start",
      align: "bottom",
      offset: 4,
      formatter: (v: any) => (v >= 0 ? "" : nFormatter(v, 0)),
    },
    end: {
      anchor: "end",
      align: "top",
      offset: 4,
      formatter: (v: any) => (v <= 0 ? "" : nFormatter(v, 0)),
    },
  },
};

@Component({ components: { NoDataHtml } })
export default class VerticalLineAndBarSingleChart extends Vue {
  @Prop() id!: string;
  @Prop() dataId!: string;

  noDataFound: Boolean = false;
  chart: any;
  get chartData() {
    return {
      calcs: store.getters.currentCalcs,
      yearSums: store.getters.currentYearSums,
      rangeStep: store.getters.rangeStep,
      //resize: store.getters.resize,
      umsDetailKunden: store.getters.umsDetailKunden,
      materialDetail: store.getters.materialDetail,
    };
  }
  get themeType() {
    return store.getters.themeType;
  }
  get chartType() {
    return store.getters.chartType;
  }

  mounted() {
    this.initChart();
  }

  @Watch("chartData")
  @Watch("themeType")
  @Watch("chartType")
  initChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    if (this.chartData.yearSums && this.chartData.yearSums[this.dataId]) {
      this.renderChart(
        Object.values(this.chartData.yearSums[this.dataId]),
        Object.keys(this.chartData.yearSums[this.dataId])
      );
    }

    if (this.chartData.calcs && this.chartData.calcs["BWA"]) {
      this.noDataFound = false;
      if (this.id === "chart_betriebsergebnis_1300") {
        this.renderChart(
          [
            this.chartData.calcs["BWA"]["1"]["1080"],
            this.chartData.calcs["BWA"]["1"]["1280"],
            this.chartData.calcs["BWA"]["1"]["1300"],
          ],
          ["Rohertrag", "Gesamtkosten", "Betriebsergebnis"]
        );
      } else if (this.id === "chart_ebitda_pie") {
        this.renderChart(
          [
            this.chartData.calcs["BWA"]["1"]["1240"],
            this.chartData.calcs["BWA"]["1"]["1380"],
            this.chartData.calcs["BWA"]["1"]["EBITDA"],
          ],
          ["Abschreibungen", "EBIT - Vorläufiges Ergebnis", "EBITDA"]
        );
      } else if (this.id === "chart_ebit_three_bar") {
        this.renderChart(
          [
            this.chartData.calcs["BWA"]["1"]["1345"],
            this.chartData.calcs["BWA"]["1"]["1355"],
            this.chartData.calcs["BWA"]["1"]["1380"],
          ],
          [
            "Ergebnis vor Steuern",
            "Steuern Einkünfte und Erträge",
            "EBIT - Vorläufiges Ergebnis",
          ]
        );
      }
    }

    if (this.id === "chart_material_bar" && this.chartData.materialDetail) {
      this.noDataFound = false;
      var d: any = [];
      var l: any = [];
      this.chartData.materialDetail.yearlist.forEach((item: any) => {
        d.push(item[1]);
        l.push(item[0]);
      });
      this.renderChart(d, l);
    }

    if (this.id === "chart_uk_jahre" && this.chartData.umsDetailKunden) {
      this.noDataFound = false;
      var d0 = [];
      var labels = [];
      this.chartData.umsDetailKunden.yearlist.sort(function(a: any, b: any) {
        return a[0] - b[0];
      });
      for (var i = 0; i < this.chartData.umsDetailKunden.yearlist.length; i++) {
        labels.push(this.chartData.umsDetailKunden.yearlist[i][0]);
        d0.push(this.chartData.umsDetailKunden.yearlist[i][1]);
      }
      this.renderChart(d0, labels);
    }
  }

  renderChart(d: number[], labels: string[]) {
    const style = getComputedStyle(document.body);
    const primary = style.getPropertyValue("--primary");
    const primaryLight = style.getPropertyValue("--primary-light");

    const ctx = window.document.getElementById(this.id)! as any;

    this.chart = new Chart(ctx, {
      type: this.chartType,
      plugins: [ChartDataLabels],
      data: {
        labels: labels,
        datasets: [
          {
            borderRadius: 4,
            data: d,
            borderColor: primary,
            backgroundColor: primary,
          },
        ],
      },

      options: {
        responsive: true,

        maintainAspectRatio: false,
        plugins: {
          datalabels: datalabels as any,
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function(value: any, index: any, values: any) {
                const v = labels[index];
                if (v.length > 8) {
                  return labels[index].slice(0, 8) + "...";
                } else {
                  return labels[index];
                }
              },
            },
            grid: {
              display: false,
              borderColor: "transparent",
            },
          },
          y: {
            ticks: {
              callback: function(v: any) {
                return nFormatter(v, 0);
              },
            },
            grid: {
              display: true,
              borderDash: [8, 4],
              borderColor: "transparent",
            },
          },
        },
      },
    });
  }

  composeData(data: any, key: any) {
    if (!data) {
      return [];
    }
    if (Object.keys(data).length === 0) {
      return [];
    }
    var result: any = [];
    for (var i = 1; i < 13; i++) {
      var k =
        (i < 10 ? "0" + i : i) +
        "." +
        (store.getters.selectedYear && store.getters.selectedYear[0])
          .toString()
          .substr(2);
      result[k] = data[i][key];
    }
    if (store.getters.rangeStep === "quarterly") {
      result = combineMonthToQuarter(result);
    }
    return result;
  }
}
